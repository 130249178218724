import React from "react"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import stewartImage from "assets/img/avatar.jpg"
import kelsieImage from "assets/img/avatar.jpg"
import samImage from "assets/img/avatar.jpg"
import typographyStyle from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"

const Team = ({ classes }) => (
  <div id="team">
    <div className={classes.center} style={{display: 'flex', justifyContent: 'center'}}>
      <h2>Meet the Team</h2>
    </div>
    <br />
    <GridContainer
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <GridItem xs={12} sm={2}>
        <h4>Samantha Fowler</h4>
        <img
          src={samImage}
          alt="..."
          className={
            classes.imgRaised +
            " " +
            classes.imgRoundedCircle +
            " " +
            classes.imgFluid
          }
        />
      </GridItem>
      <GridItem xs={12} sm={2}>
        <h4>Kelsie Braidwood</h4>
        <img
          src={kelsieImage}
          alt="..."
          className={
            classes.imgRaised +
            " " +
            classes.imgRoundedCircle +
            " " +
            classes.imgFluid
          }
        />
      </GridItem>
      <GridItem xs={12} sm={2}>
        <h4>Stewart Caldwell</h4>
        <img
          src={stewartImage}
          alt="..."
          className={
            classes.imgRaised +
            " " +
            classes.imgRoundedCircle +
            " " +
            classes.imgFluid
          }
        />
      </GridItem>
    </GridContainer>
    <GridContainer />
  </div>
)

export default withStyles(typographyStyle)(Team)
